body {
  margin: 0;
  font-family: "Chirp", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fcf7ff;
  font-size: 15px;
  text-size-adjust: 100%;
  overflow-y: scroll;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DS Digital";
  src: url("assets/fonts/ds-digi.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Chirp";
  src: url("assets/fonts/chirp-regular-web.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Chirp Bold";
  src: url("assets/fonts/chirp-bold-web.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Chirp Heavy";
  src: url("assets/fonts/chirp-heavy-web.woff2") format("woff2");
  font-weight: normal;
}
